import { Audit, AuditStatus, PayloadAudit } from "@/interfaces/audit";
import { Filter, ItemAudit, ProductInfoAudit } from "@/interfaces/audit";
import { Product } from "@/interfaces/product";
import { EventBus } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { SecurityPinService } from "@/plugins/security-pin/security-pin.service";
import HttpService from "@/services/http.service";
import { messagesService } from "@/services/messages.service";
import { AxiosError, AxiosResponse } from "axios";
import { TablePagination } from "helix-vue-components";
import Vue from "vue";
const authorize = () => {
  const pin$ = new SecurityPinService();
  return pin$
    .ensure("security_pin.title", {
      text: "security_pin.description"
    })
    .then(
      pin => {
        return pin;
      },
      () => {
        EventBus.$emit("notify", {
          icon: "fas fa-exclamation-circle",
          text: "security_pin.required_message",
          color: "error"
        });
        return null;
      }
    );
};

class AuditService extends HttpService {
  protected uri: string = "inventory/audits";

  public async authorize() {
    return authorize();
  }

  public async searchRooms() {
    this.uri = "/rooms";
    this.query.no_pagination = true;
    try {
      const categories = await super.get({ no_pagination: true });
      this.uri = "/inventory/audits";
      delete this.query.no_pagination;
      return categories.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async searchProductCategories(name?: string) {
    this.uri = "/catalog/product_categories";
    try {
      let query;
      if (name) {
        query = {
          "q[name_contains]": name
        };
      }
      const productCategories = await super.get({
        no_pagination: true,
        ...query,
        sort: "name"
      });
      return productCategories.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async openAudit(
    rooms: number[],
    productCategories: number[],
    realTime: boolean
  ): Promise<any> {
    this.uri = "/inventory/operations/audit";
    this.query = {};
    try {
      const response = await super.post({
        categories: productCategories,
        inventory_locations: rooms,
        real_time_audit: realTime
      });
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async updateItemAudited(
    realTime: boolean,
    operationUid: string,
    payload: { audit_item: PayloadAudit }
  ): Promise<any> {
    this.uri = `/inventory/operations/${operationUid}/forward`;
    this.query = {};
    try {
      const response = await super.put({}, payload);
      if (realTime) {
        messagesService.showMessage(
          "fal fa-exclamation-triangle",
          String(i18n.t("audit.change_item_real_time")),
          "warning"
        );
      } else {
        messagesService.renderSuccessMessage("audit.item_updated_successfully");
      }

      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }
  public async resumeAudit(operationUid: string): Promise<any> {
    const pinCode = await authorize();
    if (!pinCode) {
      return null;
    }
    this.uri = `/inventory/operations/${operationUid}/resume`;
    try {
      const response = await super.put({}, {}, false, pinCode);
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(i18n.t("audit.notification_in_progress")),
        "warning"
      );
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async saveAudit(operationUid: string): Promise<any> {
    const pinCode = await authorize();
    if (!pinCode) {
      return null;
    }
    this.uri = `/inventory/operations/${operationUid}/pause`;
    try {
      const response = await super.put({}, {}, false, pinCode);
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(i18n.t("audit.notification_in_progress")),
        "warning"
      );
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async cancelAudit(operationUid: string): Promise<any> {
    const pinCode = await authorize();
    if (!pinCode) {
      return null;
    }
    this.uri = `/inventory/operations/${operationUid}/abort`;
    try {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(i18n.t("audit.notification_in_progress")),
        "warning"
      );
      const response: AxiosResponse = await Vue.axios({
        method: "PATCH",
        url: this.uri,
        headers: { Pincode: pinCode }
      });
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async metrcAdjustmentReasons() {
    this.uri = "/packages/v1/adjust/reasons";
    try {
      const response = await super.get();
      return response.data.data;
    } catch (e) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        i18n.t("audit.reasons_not_available").toString(),
        "error"
      );
    }
  }

  public async closeAudit(
    operationUid: string,
    pinCode: string | null,
    bioTrackTraceEnabled: boolean
  ): Promise<any> {
    if (!pinCode) {
      return null;
    }
    this.uri = `/inventory/operations/${operationUid}/close`;
    try {
      const response = await super.put({}, {}, false, pinCode);
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(i18n.t("audit.notification_in_progress")),
        "warning"
      );
      return response;
    } catch (e) {
      return bioTrackTraceEnabled
        ? this.auditPopupErrorMessage(e)
        : { success: false, errors: messagesService.parseMetrcError(e) };
    }
  }
  public auditPopupErrorMessage(error: any) {
    if (
      error.response.data.error &&
      error.response.data.error.message === "Forbidden: wrong pin code"
    ) {
      return { status: "errorWrongPin" };
    }
    return { status: "error", message: error.response.data.message };
  }

  public async getAudits(pagination: TablePagination): Promise<Audit[]> {
    this.uri = "/inventory/batch_audits";
    const query = {
      page: pagination.currentPage,
      per_page: pagination.itemsPerPage,
      sort: "-created_at",
      "q[status_in]": [
        AuditStatus.BUILDING,
        AuditStatus.OPENED,
        AuditStatus.PAUSED,
        AuditStatus.ERROR,
        AuditStatus.TRC_CONNECTION_ERROR
      ]
    };
    try {
      const audits = await super.get(query);
      return audits.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public async getAuditsId(operationUid: string): Promise<string> {
    this.uri = "/inventory/batch_audits";
    const query = {
      "q[operation_reference_eq]": operationUid
    };
    try {
      const audits = await super.get(query);
      if (audits.data.data.length) {
        return audits.data.data[0].operation_uid;
      }
      return "";
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return "";
    }
  }

  public async getAudit(operationUid: string): Promise<Audit | null> {
    try {
      const audits = await Vue.axios({
        method: "GET",
        url: `/inventory/batch_audits/${operationUid}`
      });
      return audits.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async getItemsCurrentAudit(
    operationUid: string,
    batchUid: string,
    inventoryLocationsId: number[]
  ): Promise<ItemAudit | null> {
    this.uri = `/inventory/batch_audits/${operationUid}/items`;
    try {
      const query = {
        "q[batch_uid_or_biotrack_traceability_id_eq]": batchUid,
        "q[inventory_location_id_eq]": inventoryLocationsId
      };
      const audits = await super.get(query);
      return audits.data.data[0];
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async getCurrentAuditSummary(
    operationUid: string
  ): Promise<Audit | null> {
    this.uri = `/inventory/batch_audits/${operationUid}/summary`;
    try {
      const audits = await super.get();
      return audits.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async getProductAudits(data: {
    operationUid: string;
    filter: Filter;
    pagination: TablePagination | null;
    batchUid?: string;
    columnToSortBy?: string;
    sortDescending?: boolean;
  }): Promise<{ data: ItemAudit[]; products: ProductInfoAudit[] } | null> {
    let query = {};
    if (data.filter.rooms.length) {
      query = { ...query, "q[inventory_location_id_eq]": data.filter.rooms };
    }
    if (data.filter.productCategories.length) {
      query = {
        ...query,
        "q[product_category_id_eq]": data.filter.productCategories
      };
    }
    if (data.pagination) {
      query = {
        ...query,
        page: data.pagination.currentPage,
        per_page: data.pagination.itemsPerPage
      };
    }

    if (data.batchUid && data.batchUid !== undefined) {
      query = {
        ...query,
        "q[batch_uid_or_biotrack_traceability_id_eq]": data.batchUid
      };
    }
    if (data.columnToSortBy) {
      query = {
        ...query,
        sort: `${data.sortDescending ? "-" : ""}${data.columnToSortBy}`
      };
    }
    this.uri = `/inventory/batch_audits/${data.operationUid}/products`;
    try {
      const audits = await this.get(query);
      return { data: audits.data.data, products: audits.data.products };
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async getBatchesInProductAudits(
    operationUid: string,
    sku: string,
    filter: Filter,
    pagination: TablePagination | null,
    batchTraceId: string
  ): Promise<ItemAudit[]> {
    let query: any = {
      "q[inventory_location_id_eq]": filter.rooms,
      "q[batch_uid_or_biotrack_traceability_id_eq]": batchTraceId
    };
    if (pagination) {
      query = {
        ...query,
        page: pagination.currentPage,
        per_page: pagination.itemsPerPage
      };
    }

    this.uri = `/inventory/batch_audits/${operationUid}/products/${sku}/batches`;
    try {
      const audits = await super.get(query);
      return audits.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }

  public async getBatchesCurrentAudit(data: {
    operationUid: string;
    filter: Filter;
    pagination: TablePagination | null;
    batchUid?: string;
    columnToSortBy?: string;
    sortDescending?: boolean;
  }): Promise<{ data: ItemAudit[] } | null> {
    try {
      this.uri = `/inventory/batch_audits/${data.operationUid}/items`;
      let query: any = {
        "q[inventory_location_id_eq]": data.filter.rooms
      };
      if (data.pagination) {
        query = {
          ...query,
          page: data.pagination.currentPage,
          per_page: data.pagination.itemsPerPage
        };
      }
      if (
        data.filter.productCategories &&
        data.filter.productCategories.length
      ) {
        query = {
          ...query,
          "q[product_category_id_eq]": data.filter.productCategories
        };
      }

      if (data.batchUid && data.batchUid !== undefined) {
        query = {
          ...query,
          "q[batch_uid_or_biotrack_traceability_id_eq]": data.batchUid
        };
      }

      if (data.columnToSortBy) {
        query = {
          ...query,
          sort: `${data.sortDescending ? "-" : ""}${data.columnToSortBy}`
        };
      }
      const audits = await super.get(query);
      return { data: audits.data.data };
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }
}

export const auditService: AuditService = new AuditService();
